import React, {Component} from "react";
import SignUpLayout from "./SignUpLayout";
import CountrySelect from "./views/CountrySelect";
import Registration from "./views/Registration";
import MonitorRegistration from "./views/MonitorRegistration";
import WaitingList from "./views/WaitingList";
import AddedToWaitingList from "./views/AddedToWaitingList";
import Navigation from "../lib/Navigation";
import {retainSearchParams} from "../lib/PermanentUrlParams";
import {recordRegistrationCompleted} from "../lib/Analytics";
import {getPermanentQueryParams} from "../config";
import {fromJS} from "immutable";
import Cookies from "js-cookie";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import "./OnboardingPage.css";
import { filterAndSortCountries } from "../lib/CountriesAndStates";
import { withRouter } from "react-router";
import {queryToMap} from "../lib/QueryParser";

class OnboardingPage extends Component {

  constructor(props) {
    super(props);
    let query = queryToMap(this.props.location.search);
    let view = "initial";
    if (query.get("view", "") === "waiting-list") {
      view = "waitingList";
    }
    
    this.state = {
      view: view,
      countriesInShops: null,
      countryCode: "",
      referralCode: "",
      onboardingId: "",
      email: "",
      password: "",
      assumedCountryCode: "",
    }
  }

  componentDidMount() {
    const assumedCountryCode = Cookies.get("assumed_country") || "";
    // We always need it as upper case during the onboarding
    this.setState({ assumedCountryCode: assumedCountryCode.toUpperCase() });
  }

  render() {
    const layoutBG = this.state.view === "waitingList"
      ? SignUpLayout.BACKGROUNDS.WAITING_LIST
      : SignUpLayout.BACKGROUNDS.ACCOUNT_CREATION;

    return <Query query={COUNTRIES_IN_SHOPS} fetchPolicy="network-only"
      onCompleted={(data) => this.updateCountriesInShops(data.countriesInShops)}>
        {({ data, loading, error }) => {
          return (
            <SignUpLayout className="OnboardingPage"
              background={layoutBG}
              noTestimonials={layoutBG === SignUpLayout.BACKGROUNDS.WAITING_LIST}>
              {this.renderView(this.state.view)}
              <div className="preloadGraphics">
              </div>
            </SignUpLayout>
          )
        }}
      </Query>
  }

  updateCountriesInShops(countriesInShops) {
    this.setState({ countriesInShops: fromJS(filterAndSortCountries(countriesInShops)) })
  }

  // flow: CountrySelect => Registration => MonitorRegistration 
  renderView(view) {
    switch(view) {
      // Stage 2: account information
      case "registration":
        return <Registration 
                  onBack={this.gotoDefaultView.bind(this)}
                  onSignIn={Navigation.signIn}
                  countryCode={this.state.countryCode}
                  onRegistrationStarted={this.handleRegistrationStarted.bind(this)} />

      case "monitorRegistration":
        return <MonitorRegistration
                  onboardingId={this.state.onboardingId} 
                  onBack={this.changeView.bind(this, "registration")} 
                  onSignIn={this.handleSignIn.bind(this)} />

      case "waitingList":
        return <WaitingList
                  onBack={this.changeView.bind(this, "initial")}
                  onAddedToWaitingList={this.changeView.bind(this, "waitingListConfirmation")}
                  onSignUp={this.gotoDefaultView.bind(this)}
                  countriesInShops={this.state.countriesInShops}
                  assumedCountryCode={this.state.assumedCountryCode} />
                
      case "waitingListConfirmation":
        return <AddedToWaitingList
                  onBack={this.handleCancelOnboarding}
                  onSignUp={this.gotoDefaultView.bind(this)} />

      // Stage 1
      default:
        return <CountrySelect 
                  onSelected={this.handleCountrySelected.bind(this)} 
                  onWaitingList={this.changeView.bind(this, "waitingList")}
                  onSignIn={Navigation.signIn}
                  onBack={this.handleCancelOnboarding}
                  countriesInShops={this.state.countriesInShops}
                  assumedCountryCode={this.state.assumedCountryCode} />
    }
  }

  gotoDefaultView() {
    this.changeView("");
  }

  changeView(view) {
    this.setState({view: view});
  }

  handleCountrySelected(values) {
    this.setState({countryCode: values.get("countryCode")});
    this.changeView("registration");
  }

  handleRegistrationStarted(onboardingId, email, password, referralCode) {
    this.setState({onboardingId: onboardingId, view: "monitorRegistration",
      email: email, password: password});
  }

  handleCancelOnboarding() {
    Navigation.signIn();
  }

  handleSignIn() {
    recordRegistrationCompleted(this.state.countryCode);
    let query = queryToMap(this.props.location.search);
    let params = retainSearchParams(query, getPermanentQueryParams())
                  .set("new", "1");
    this.props.auth.loginWithCredentials(this.state.email, this.state.password, params.toJS());
  }


}

// The onboarding has 2 landing page: country selection and waiting list.
// In both cases we need the list of countries available for the shop!
const COUNTRIES_IN_SHOPS = gql`
  query countriesInShops {
    countriesInShops {
      code
      name
    }
  }
`;

export default withRouter(OnboardingPage);