import React, {Component} from "react";
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import FooterActions from "../../shared/FooterActions";
import Form from "../forms/WaitingListForm";
import {validatePresence, validateAcceptance} from "../../lib/Validators";
import gql from "graphql-tag";
import {subscribeWaitingList} from "../../lib/api/WaitingListApi";
import {graphql} from "react-apollo";
import {Map, fromJS} from "immutable";
import Button from "../../shared/components/Button";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import { withTranslation } from 'react-i18next';

function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "email");
  errors = validatePresence(errors, values, "countryCode");
  errors = validatePresence(errors, values, "kind");
  errors = validateAcceptance(errors, values, "policyAcceptance", "yes");
  errors = validateAcceptance(errors, values, "marketingAcceptance", "yes");

  return errors.toJS();
}


class WaitingList extends Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    return subscribeWaitingList(values.get("name"), values.get("email"), values.get("countryCode"), values.get("kind"))
      .then((result) => {
        if (result.response && result.response.success === true) {
          this.props.onAddedToWaitingList()
        } else {
          this.setState({ errors: {_error: this.props.t("common:error_try_again")}});
        }
      })
      .catch((err) => {
        this.setState({ errors: {_error: this.props.t("common:error_try_again")}});
      });
  }

  render() {
    let {data} = this.props;

    // Only show the countries which are not already available for shopping
    let countriesNotInShop = [];
    if (data) {
      if (data.error) {
        // avoid setting the errors state several times when server is down
        if (this.state.errors === []) {
          this.setState({ errors: {_error: this.props.t("common:error_try_again")}});
        }
      } else if (!data.loading && this.props.countriesInShops) {
        const codesToFilter = this.props.countriesInShops.map((d) => d.get("code"))
        const allSupportedCountries = filterAndSortCountries(data.countries);

        countriesNotInShop = fromJS(allSupportedCountries.filter((d) => {
          return !codesToFilter.includes(d.code)
        }));
      }
    }

    const kinds = fromJS([
      {name: this.props.t("forms:status_individual"), code: "individual"},
      {name: this.props.t("forms:status_business"), code: "business"}
    ])
    
    const initialValues = {
      kind: "individual"
    }

    return (
      <div className="WaitingList view">
        <div className="inner">
          <React.Fragment>
            <h4 className="sg-text-large u-bottom-margin-short">{this.props.t("waiting_list.title")}</h4>
            <p className="sg-text-small">{this.props.t("waiting_list.subtitle")}</p>
            <Form 
              errors={this.state.errors}
              validate={validateForm}
              initialValues={initialValues}
              cancelLabel={this.props.t("waiting_list.go_back")}
              showMarketingAcceptance={true}
              countries={countriesNotInShop}
              kinds={kinds}
              onSubmit={this.handleSubmit.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              assumedCountryCode={this.props.assumedCountryCode}
            />
            <div>
              <p className="u-no-margin">{this.props.t("waiting_list.want_create_account")}</p>
              <Button
                label={this.props.t("waiting_list.create_account")}
                link={true}
                theme={Button.THEMES.WHITE}
                onClick={this.props.onSignUp}
                className="sg-font-bold sg-text-normal"
              />
            </div>

            <div className={"u-centered sg-font-bold"}>
              <FooterActions
                showSupport={this.props.showSupport} 
                darkBackground={true}
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    )
  }

  handleCancel() {
    this.props.onBack();
  }

}

const COUNTRIES = gql`
  query countries {
    countries {
      code
      name
    }
  }
`;

const withQuery = graphql(COUNTRIES);

export default withTranslation("auth")(connect(null, {showSupport})(withQuery(WaitingList)));