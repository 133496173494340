import React, { Component, Suspense } from 'react';
import MessageBus from "./lib/MessageBus";
import WindowContainer from "./shared/windows/WindowContainer";
import NotificationsContainer from "./shared/NotificationsContainer";
import { addClassName, cx } from "./shared/lib/ClassSet";
import {patchSearchParams, retainSearchParams} from "./lib/PermanentUrlParams";
import CustomerSupport from "./support/CustomerSupport";
import SystemVersion from "./shared/SystemVersion";
import {getPermanentQueryParams, isDevelopment} from "./config";
import {withRouter, Route, Switch, Redirect} from "react-router";
import NotFoundPage from "./NotFoundPage";
import CallbackPage from "./login/CallbackPage";
import LandingPage from "./LandingPage";
import GoogleNewPage from "./login/GoogleNewPage";
import SignInPage from "./login/SignInPage";
import SignOutPage from "./login/SignOutPage";
import ForgotPasswordPage from "./login/ForgotPasswordPage";
import OnboardingPage from "./onboarding/OnboardingPage";
import InvitationPage from "./onboarding/InvitationPage";
import PasswordChangePage from "./hooks/PasswordChangePage";
import EmailVerificationPage from "./hooks/EmailVerificationPage";
import ClientSectionContainer from "./shop/ClientSectionContainer";
import AnonymousCheckoutPage from "./anonymous_checkout/AnonymousCheckoutPage";
import Product8PackLinkedPage from './public_keyed_pages/product_8_pack_eol/Product8PackLinkedPage';
import {queryToMap} from "./lib/QueryParser";
import LoadingCircle from './shared/components/forms/LoadingCircle';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from './lib/Locales';
import Navigation from './lib/Navigation';
import './App.css';
import i18n from './i18n';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasActiveModal: false,
      savedScrollPosition: 0,
    }
  }

  // On iOS, when safari's address bar (dis)-appears, we can see through the
  // modal being displayed. In order to prevent that, we hide the ShopLayout
  // on modal opening, keep track of the scroll position, in order to be able
  // to restore it when we close the modal.
  componentDidMount() {
    if (this.props.auth.isAuthenticated()) {
      this.props.requestProfile();
    }
    let self = this;

    MessageBus.subscribe("navigation", "goto", (data) => {
      let query = queryToMap(self.props.location.search);
      self.props.history.push(patchSearchParams(query, data.url), data.state);
    })
    MessageBus.subscribe("navigation", "replaceHistory", (data) => {
      let query = queryToMap(self.props.location.search);
      self.props.history.replace(patchSearchParams(query, data.url), data.state);
    });
    MessageBus.subscribe("navigation", "goBack", (data) => {
      self.props.history.goBack();
    })
    MessageBus.subscribe("navigation", "scrollTo", (data) => {
      console.debug("MessageBus navigation.scrollTo", data);
      window.scrollTo(data.x || 0, data.y || 0);
    })
    MessageBus.subscribe("auth", "login", (data) => {
      console.debug("MessageBus auth.login", data);
      let query = queryToMap(self.props.location.search);
      self.props.auth.login(retainSearchParams(query, getPermanentQueryParams()).toJS());
    })
    MessageBus.subscribe("auth", "logout", (data) => {
      console.debug("MessageBus auth.logout", data);
      self.props.auth.logout();
    })
    MessageBus.subscribe("auth", "profileUpdated", (data) => {
      self.props.auth.changeCustomerName(data.name);
    })    
    MessageBus.subscribe("socket", "disconnected", () => {
      self.props.setSocketDisconnected();
    })
    MessageBus.subscribe("socket", "connected", () => {
      self.props.setSocketConnected();
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalActive !== this.props.modalActive) {
      // Save/restore the scroll position
      if (this.props.modalActive) {
        this.setState({ hasActiveModal: true, savedScrollPosition: window.scrollY });
      }
      else {
        this.setState({ hasActiveModal: false });
        // Wait for the content to be visible again
        window.setTimeout(() => window.scrollTo(0, this.state.savedScrollPosition), 1);
      }
    }
  }

  render() {
    const { location, match } = this.props;
    const localeBase = match.url;

    // Hack to differenciate our non client section pages with full background
    // images from the rest.
    const isClientSection = this.props.location.pathname.includes("/client-section");
    let classes = cx({
      "App": true,
      "u-development": isDevelopment(),
      "clientSection": isClientSection,
      "hasActiveModal": this.state.hasActiveModal,
      "locale-en": i18n && i18n.language === "en",
      "locale-de": i18n && i18n.language === "de",
    })
    classes = addClassName(true, classes, this.props.mediaQueryClassName);
    return (
      <Suspense fallback={<LoadingCircle className="centeredWithinViewport" />}>
        <EnforceLocaleInPath location={location} match={match} />
        <div className={classes}>
          <Switch>
            <Route path={localeBase + "/callback"}>
              <CallbackPage auth={this.props.auth} />
            </Route>
            <Redirect from="/en/waiting-list" to={`/en/signup?view=waiting-list`} />
            <Redirect from="/de/waiting-list" to={`/de/signup?view=waiting-list`} />
            <Route path={localeBase + "/new"}>
              <GoogleNewPage auth={this.props.auth} />
            </Route>
            <Route path={localeBase + "/signin"}>
              <SignInPage auth={this.props.auth} />
            </Route>
            <Route path={localeBase + "/signout"}>
              <SignOutPage auth={this.props.auth} />
            </Route>
            <Route path={localeBase + "/forgot-password"}>
              <ForgotPasswordPage />
            </Route>
            <Route path={localeBase + "/signup"}>
              <OnboardingPage auth={this.props.auth} />
            </Route>
            <Route path={localeBase + "/invite/:inviteId"}>
              <InvitationPage auth={this.props.auth} />
            </Route>
            <Route path={localeBase + "/8-bottles-cases-transition-preference/:emailKey"} render={({match}) => <Product8PackLinkedPage emailKey={match.params.emailKey} auth={this.props.auth} />} />
            <Route path={localeBase + "/checkout/:cartId"} render={({match}) => <AnonymousCheckoutPage cartId={match.params.cartId} auth={this.props.auth} />} />            
            <Route path={localeBase + "/s/password-change"}>
              <PasswordChangePage />
            </Route>
            <Route path={localeBase + "/s/email-verification"}>
              <EmailVerificationPage />
            </Route>
            <Route path={localeBase + "/client-section"}>
              <ClientSectionContainer base={localeBase} auth={this.props.auth} />
            </Route>
            <Route exact path={localeBase}>
              <LandingPage auth={this.props.auth} base={localeBase} />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
          <SystemVersion />
          <CustomerSupport
            show={!this.props.modalActive}
            />
          <WindowContainer
            windows={this.props.windows}
            modals={this.props.modals}
            modalActive={this.props.modalActive}
          />
          <NotificationsContainer 
            getNotifications={this.props.getNotifications}
            />
        </div>
      </Suspense>
    );
  }
}

// In index.js, we defined a base route whose path is `/:locale` because all of
// our paths must include the locale at the base. This component makes sure
// this is the case, and also acts as a convertor for old links which lack the
// locale base.
//
// => if the base is not a locale, it redirects to `/en${path}`
function EnforceLocaleInPath(props) {
  const {
    location: {
      pathname,
      search = "",
      state = {},
    },
    match: {
      params: {
        locale: maybeLocale,
      }
    }
  } = props;

  if (!SUPPORTED_LOCALES.includes(maybeLocale)) {
    // URL lacks the locale part, so we must use the default
    // SetTimeout because this can be called before the MessageBus subscriptions are ready
    window.setTimeout(() => Navigation.replaceHistory(pathname + search, state, DEFAULT_LOCALE), 1);
  }

  return null;
}

export default withRouter(App);
